import React from 'react';
import {
  CONTROL_CHECKBOXES,
  CONTROL_FILE,
  CONTROL_TERMS,
  fieldHasOptions
} from '../../../pages/Account/components/RxFormCustomization/FormBuilder/helpers';

const isValidMarker = marker => marker && typeof marker === 'object' && marker.color;

const extractMarkers = (control, value) => {
  if (!value) {
    return [];
  }

  const markers = [];

  if (fieldHasOptions(control.system_type)) {
    const values = value.split(',').map(val => Number(val));
    control.props.options.forEach(option => {
      if (values.includes(option.id) && option.marker) {
        markers.push({
          value: option.id,
          ...option.marker
        });
      }
    });
  } else if (control.marker) {
    markers.push(control.marker);
  }

  const validMarkers = markers.filter(isValidMarker);

  const uniqueMarkers = validMarkers.filter((marker, index, self) => {
    return (
      self.findIndex(sub => sub.symbol === marker.symbol && sub.color === marker.color) === index
    );
  });

  // return only one marker if it's the same for all options
  return uniqueMarkers.length === 1 ? uniqueMarkers : markers;
};

const createWrapperStyle = markers => {
  return {
    style: {
      backgroundColor: `#${markers[0].color}`
    }
  };
};

const getItemStyling = (optionId, markers) => {
  const marker = markers.find(item => String(item.value) === String(optionId));
  return marker
    ? {
        backgroundColor: `#${marker.color}`
      }
    : {};
};

export default form => {
  return form.controls
    .filter(control => {
      return control.system_type !== 'existing' && control.system_type !== CONTROL_FILE;
    })
    .map(control => {
      return {
        custom: true,
        attribute: control.system_name,
        customLabel: control.props.label,
        generateContentProps: (value, isManagement) => {
          const result = {
            label: value,
            wrapperProps: {},
            itemProps: {}
          };

          if (value === null || value === undefined) {
            return result;
          }
          const markers = extractMarkers(control, value);
          result.wrapperProps =
            isManagement && markers.length === 1 ? createWrapperStyle(markers) : {};

          if (fieldHasOptions(control.system_type)) {
            let options = [];
            if (control.system_type === CONTROL_CHECKBOXES) {
              const values = value.split(',').map(val => Number(val));
              options = control.props.options.filter(item => values.indexOf(item.id) !== -1);
            } else {
              options = control.props.options.filter(item => item.id === Number(value));
            }

            if (markers.length > 1) {
              result.label = (
                <ul>
                  {options.map(option => (
                    <li style={getItemStyling(option.id, markers)}>- {option.name}</li>
                  ))}
                </ul>
              );
            } else if (options.length === 1) {
              result.label = options[0].name;
            } else {
              result.label = (
                <ul>
                  {options.map(option => (
                    <li>- {option.name}</li>
                  ))}
                </ul>
              );
            }
            return result;
          }

          if (control.system_type === CONTROL_TERMS) {
            result.value = value === '1' ? 'Agreed' : 'Disagreed';
          }
          return result;
        }
      };
    });
};

import React, { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import Card from '@material-ui/core/Card';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../../components/LoadingIndicator';
import { UI_SETTINGS } from '../../constants';
import OrganizationForm from '../../components/OrganizationForm';
import {
  fetchOrganizationAction,
  UPDATE_ORGANIZATION_PREFIX,
  updateOrganizationAction
} from '../../reducers/organizations';
import OrganizationMembers from '../../components/OrganizationMembers';
import InviteOrganizationMember from '../../components/InviteOrganizationMember';
import AddOrganizationMember from '../../components/AddOrganizationMember';
import Can from '../../components/Can';
import OrganizationBillingAddress from './components/OrganizationBillingAddress';
import CustomizeFees from './components/CustomizeFees';
import { updateRefinements } from '../../reducers/globalSearch';
import SubSectionWrapper, {
  SubSectionActionWrapper
} from '../Account/components/SubSectionWrapper';
import RxFormPreference from '../Account/components/RxFormPreference';
import RxFormCustomization from '../Account/components/RxFormCustomization';
import Notifications from '../Account/components/Notifications';
import BracketLibraries from '../Account/components/BracketLibraries';
import ArchiveOrganization from './components/ArchiveOrganization';
import UnArchiveOrganization from './components/UnArchiveOrganization';
import ContactInformation from '../../components/ContactInformation';
import { fetchOrganizationUserPermissions } from '../../reducers/auth';
import OrganizationInstallersManagement from './components/OrganizationInstallersManagement';
import ArchWireLibraries from '../Account/components/ArchWireLibraries';
import HitCard from '../../components/Hits/OrganizationHit/HitCard';
import { isTechnicianOrAdmin } from '../../helpers';
import EasyRxSetup from '../../components/EasyRxSetup';

const useStyles = makeStyles(() => ({
  quickLinkCard: {
    boxShadow: 'none'
  },
  mainContainer: {
    paddingBottom: '600px'
  }
}));

const UpdateOrganization = ({ match: { params } }) => {
  const classes = useStyles();

  const [isLoadingLocal, setIsLoadingLocal] = useState(true);
  const currentUser = useSelector(state => state.auth.user);

  const dispatch = useDispatch();
  const history = useHistory();
  const organizationId = parseInt(params.id, 10);
  const globalOrgTypeFilter = useSelector(state => state.globalSearch.organization_type);

  const organization = useSelector(state => {
    return state.organizations.items[
      state.organizations.items.findIndex(item => item.id === organizationId)
    ];
  });

  const metadata = useSelector(state => {
    return state.organizations.metadata[organizationId] || null;
  });

  const isUpdating = useSelector(state =>
    createLoadingSelector([UPDATE_ORGANIZATION_PREFIX])(state)
  );

  useEffect(() => {
    setIsLoadingLocal(true);
    dispatch(fetchOrganizationUserPermissions(organizationId));
    dispatch(
      fetchOrganizationAction(organizationId, [
        'paymentInfo.billingAddress',
        'users',
        'credits',
        'contract'
      ])
    ).then(() => {
      setIsLoadingLocal(false);
    });
  }, [dispatch, organizationId]);

  const OrgTypeUniqueKey = org => `${org.organization_type}_${org.organization_type_id}`;

  const onUpdate = values => {
    const oldOrgType = OrgTypeUniqueKey(organization);

    return dispatch(updateOrganizationAction(values, organizationId)).then(response => {
      if (!isNil(response)) {
        const newOrgType = OrgTypeUniqueKey(response);

        if (oldOrgType !== newOrgType) {
          const orgTypeFilter = globalOrgTypeFilter.filter(orgType => orgType !== oldOrgType);
          dispatch(updateRefinements('organization_type', orgTypeFilter));
        }

        history.push('/organizations-users');
      }
    });
  };

  if (isLoadingLocal) {
    return <LoadingIndicator />;
  }

  if (!organization) {
    return null;
  }

  const subTitle = () => {
    const tags = [];
    if (organization.disabled_at) {
      tags.push(
        <Chip
          key="Organization Disabled"
          size="small"
          label="Organization Disabled"
          color="secondary"
        />
      );
    }

    if (organization.deleted_at) {
      tags.push(<Chip key="Archived" size="small" label="Archived" color="secondary" />);
    }

    return <>{tags}</>;
  };

  return (
    <Box width={UI_SETTINGS.formWidth} mx="auto" className={classes.mainContainer}>
      {currentUser && isTechnicianOrAdmin(currentUser) && metadata && metadata.quick_glance && (
        <Box my={2}>
          <Card className={classes.quickLinkCard}>
            <HitCard
              hit={metadata.quick_glance}
              showDetails={false}
              showHeader={false}
              exclude={['Bracket Libraries']}
            />
          </Card>
        </Box>
      )}
      <Box my={2}>
        <Card>
          <SubSectionWrapper title="Organization Information" subTitle={subTitle()}>
            <OrganizationForm
              ContentWrapper={({ children }) => <div>{children}</div>}
              ActionsWrapper={SubSectionActionWrapper}
              isSaving={isUpdating}
              onSubmit={onUpdate}
              organization={organization}
              mode={organization.disabled_at || organization.deleted_at ? 'show' : null}
            />
          </SubSectionWrapper>
        </Card>
        <Box my={2}>
          <OrganizationMembers
            organizationId={organizationId}
            readOnly={!!(organization.disabled_at || organization.deleted_at)}
          />
        </Box>
        {!organization.disabled_at && !organization.deleted_at && (
          <Box my={2}>
            <InviteOrganizationMember organizationId={organizationId} />
          </Box>
        )}
      </Box>
      <Can
        permissions={['organizations.add_member', 'organizations.manage']}
        yes={() => (
          <>
            {!organization.disabled_at && !organization.deleted_at && (
              <Box my={2}>
                <AddOrganizationMember organization={organization} />
              </Box>
            )}
            <Box my={2}>
              <ContactInformation
                organizationId={organization.id}
                readOnly={!!(organization.disabled_at || organization.deleted_at)}
              />
            </Box>
          </>
        )}
      />
      <Box my={2}>
        <OrganizationBillingAddress
          organizationId={organizationId}
          readOnly={!!(organization.disabled_at || organization.deleted_at)}
        />
      </Box>
      {!organization.disabled_at && !organization.deleted_at && (
        <>
          <Can
            permissions={['organization.rx-form-preference.update']}
            yes={() => (
              <Box my={2}>
                <RxFormPreference organization={organization} />
              </Box>
            )}
          />

          <Can
            permissions={['rx-forms.customize-organization-layout']}
            yes={() => (
              <Box my={2}>
                <RxFormCustomization organization={organization} />
              </Box>
            )}
          />

          <Can
            permissions={['organization.pricing.update']}
            yes={() => (
              <Box my={2}>
                <CustomizeFees organization={organization} />
              </Box>
            )}
          />
          <Can
            permissions={['installers.manage']}
            yes={() => (
              <Box my={2}>
                <OrganizationInstallersManagement organizationId={organizationId} />
              </Box>
            )}
          />
          <Can
            permissions={['organization.notification-settings.update']}
            yes={() => (
              <Box my={2}>
                <Notifications organizationId={organizationId} />
              </Box>
            )}
          />
          <Can
            permissions={[
              'bracket-libraries.manage',
              'bracket-libraries.update',
              'organization.bracket-libraries.view'
            ]}
            organizationId={organization.id}
            yes={() => (
              <Box my={2}>
                <BracketLibraries organization={organization} />
              </Box>
            )}
          />
          <Can
            permissions={[
              'arch-wires.create',
              'arch-wires.update',
              'arch-wires.manage',
              'organization.arch-wires.update'
            ]}
            organizationId={organization.id}
            yes={() => (
              <Box my={2}>
                <ArchWireLibraries organization={organization} />
              </Box>
            )}
          />
        </>
      )}
      {!organization.deleted_at && (
        <Can
          permissions={['organization.archive']}
          yes={() => (
            <Box my={2}>
              <ArchiveOrganization organization={organization} />
            </Box>
          )}
        />
      )}
      {!!organization.deleted_at && (
        <>
          <Can
            permissions={['organization.un-archive']}
            yes={() => (
              <Box my={2}>
                <UnArchiveOrganization organization={organization} />
              </Box>
            )}
          />
        </>
      )}
      <Box my={2}>
        <EasyRxSetup
          isSaving={isUpdating}
          onSubmit={onUpdate}
          organization={organization}
          mode={organization.disabled_at || organization.deleted_at ? 'show' : null}
        />
      </Box>
    </Box>
  );
};

UpdateOrganization.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

UpdateOrganization.defaultProps = {
  match: { params: {} }
};

export default UpdateOrganization;

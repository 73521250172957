import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty, isNull, get } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { produce } from 'immer';
import red from '@material-ui/core/colors/red';
import DataTable from '../../components/DataTable';
import SearchInput from '../../components/SearchInput';
import createLoadingSelector from '../../selectors/loading';
import {
  FETCH_PATIENTS_PREFIX,
  fetchPatientsAction,
  fetchPatientCountsPerStatusAction,
  setPatientsGlobalParams
} from '../../reducers/patients';
import { NEW_PATIENT_STATUSES, REQUEST_STATUS } from '../../constants';
import Content from '../../components/Layout/Content';
import Can from '../../components/Can';
import LocationSelectorCurrentOrganizationHandler from '../../components/LocationSelector/LocationSelectorCurrentOrganizationHandler';
import PatientModificationWarning from '../../components/PatientModificationWarning';
import { isCompletedPatient } from '../../helpers';
import { openPatientModificationWarning } from '../../reducers/modals';
import { ASSIGNMENTS } from '../../components/RxForm/constants';
import OpenWithExternalApp from '../../components/OpenWithExternalApp';
import { openDIBS } from '../../helpers/dibs';
import hasPermission from '../../selectors/hasPermission';
import ExpandCollapseIconButton from '../../components/IconButtons/ExpandCollapseIconButton';
import PatientColumn from './columns/PatientColumn';
import DateColumn from '../PatientsPage/columns/DateColumn';
import RequestsColumn from '../PatientsPage/columns/RequestsColumn';

const useStyles = makeStyles(theme => ({
  searchInput: {
    marginBottom: theme.spacing(0)
  },
  totalCount: {
    border: '1px solid',
    marginLeft: '5px',
    padding: '0px 4px',
    borderRadius: '3px'
  },
  toggleOnHold: {
    color: red.A700
  },
  expandCollapseButton: {
    '& path': {
      stroke: red.A700
    }
  }
}));

const styledBy = (property, mapping, append) => props => ({
  ...mapping[props[property]],
  ...append
});

const StyledToggleButton = withStyles({
  root: styledBy(
    'color',
    {
      blue: {
        background: '#008CD2',
        color: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRightWidth: '2px',
        '&:hover': {
          background: 'rgb(0, 98, 147)'
        }
      },
      default: {
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.05)'
        }
      }
    },
    {
      '&.Mui-selected': {
        background: '#019934',
        color: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeftWidth: '0px'
      }
    }
  )
})(({ classes, color, ...other }) => <ToggleButton classes={classes} {...other} />);

const Patients = ({ match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasMount = useRef(false);
  const history = useHistory();

  const defaultSelectedPatientStatus = 'awaiting_approval';

  const patientCountsPerStatus = useSelector(state => state.patients.patientCountsPerStatus);
  const globalParams = useSelector(state => state.patients.globalParams);
  const isLoading = useSelector(state => createLoadingSelector([FETCH_PATIENTS_PREFIX])(state));
  const canViewArchived = useSelector(state =>
    hasPermission(state, { permissions: ['patients.view-archived'] })
  );
  const [additionalParams, setAdditionalParams] = useState({
    params: {
      ...globalParams,
      newPatientStatus: globalParams.newPatientStatus || defaultSelectedPatientStatus,
      orderBy: 'created_at',
      order: ['approved', 'completed'].includes(
        globalParams.newPatientStatus || defaultSelectedPatientStatus
      )
        ? 'desc'
        : 'asc'
    },
    resetPage: false
  });

  const rows = useSelector(state => state.patients.items);
  const total = useSelector(state => state.patients.total);

  const [selectedCaseStatus, setSelectedCaseStatus] = React.useState(
    additionalParams.params.newPatientStatus
  );

  const currentOrganization = useSelector(state => state.auth.currentOrganization);
  const [onlyOnhold, setOnlyOnhold] = React.useState(globalParams.onlyOnhold);
  const [collapseAll, setCollapseAll] = useState(true);
  const [collapsed, setCollapsed] = useState([]);

  useEffect(() => {
    if (hasMount.current) {
      setAdditionalParams(currentParameters => {
        return produce(currentParameters, draft => {
          draft.params.userId = params.userId || '';
          draft.resetPage = true;
        });
      });
    } else {
      hasMount.current = true;
    }

    dispatch(
      setPatientsGlobalParams({
        newPatientStatus: selectedCaseStatus || ''
      })
    );
  }, [params, selectedCaseStatus, dispatch]);

  useEffect(() => {
    if (!collapseAll) {
      setCollapsed([...rows.map(r => r.id)]);
    } else {
      setCollapsed([]);
    }
  }, [rows, collapseAll]);

  useEffect(() => {
    /**
     * NOTE: This useEffect resets the on-hold toggle to the unchecked state when the held patient count reaches zero,
     * which occurs when a user sets all held patients to unhold. It also sets the selected patient status back to default
     * and updates the onlyOnHold patient parameter to 'without.'
     */
    if (patientCountsPerStatus.held === 0 && globalParams.onlyOnhold) {
      setSelectedCaseStatus(globalParams.newPatientStatus || defaultSelectedPatientStatus);
      setOnlyOnhold('without');
      setAdditionalParams(currentParameters => {
        return produce(currentParameters, draft => {
          draft.params.onlyOnhold = 'without';
          draft.resetPage = true;
        });
      });
    }
  }, [patientCountsPerStatus, globalParams]);

  const handleChangeCaseStatus = (event, caseStatus) => {
    setSelectedCaseStatus(caseStatus);
    let patientStatusId = caseStatus === 'onhold' ? null : caseStatus;

    if (isNull(caseStatus)) {
      patientStatusId = 'none';
    }

    dispatch(
      setPatientsGlobalParams({
        newPatientStatus: caseStatus && onlyOnhold !== 'only' ? caseStatus : ''
      })
    );

    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.orderBy = 'created_at';
        draft.params.order = ['approved', 'completed'].includes(caseStatus) ? 'desc' : 'asc';
        draft.params.newPatientStatus = patientStatusId;
        draft.params.onlyOnhold = caseStatus === 'onhold' ? 'only' : 'without';
        draft.resetPage = true;
      })
    );
  };

  const handleUpdateData = useCallback(
    parameters => {
      const includes = [
        'patient_status',
        'patient_files',
        'user',
        'rx_form',
        'customer_request.printing_request'
      ];
      const getParams = { ...parameters, organizationId: currentOrganization.id };
      if (getParams.onlyOnhold === 'only') {
        getParams.newPatientStatus = '';
      }
      dispatch(
        fetchPatientsAction({
          ...getParams,
          includes
        })
      );
      if (getParams.organizationId) {
        dispatch(fetchPatientCountsPerStatusAction(getParams));
      }
    },
    [dispatch, currentOrganization]
  );

  const handleOnChangeSearchInput = debounce((value, additionalParameterKey) => {
    setSelectedCaseStatus('all');

    setAdditionalParams(
      produce(additionalParams, draft => {
        if (!value || !value.trim()) {
          draft.params[additionalParameterKey] = value;
          draft.params.newPatientStatus = 'all';
          draft.params.archived = 'without';
          draft.params.onlyOnhold = onlyOnhold === 'only' ? 'only' : 'without';
        } else {
          draft.params[additionalParameterKey] = value;
          draft.params.newPatientStatus = 'all';
          draft.params.archived = 'with';
          draft.params.onlyOnhold = 'with';
          draft.resetPage = true;
        }
      })
    );
  }, 500);

  const [currentPatient, setCurrentPatient] = useState(null);

  const handleShowPatient = id => {
    history.push(`/patient-details/${id}`);
  };

  const [selectedApp, setSelectedApp] = useState(null);

  const onPatientModificationWarningClose = answer => {
    if (answer) {
      const fileId = currentPatient.ios_file_id;
      if (fileId) {
        dispatch(openDIBS(selectedApp, `open_file/${fileId}`));
      }
    }
  };

  const handleChangeOnlyOnhold = event => {
    setSelectedCaseStatus(event.target.checked ? '' : defaultSelectedPatientStatus);
    setOnlyOnhold(event.target.checked ? 'only' : 'without');
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.onlyOnhold = event.target.checked ? 'only' : 'without';
        draft.params.newPatientStatus = event.target.checked ? null : defaultSelectedPatientStatus;
        draft.resetPage = true;
      })
    );
  };

  const handleChangeLocations = debounce(locations => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.locations = locations;
        draft.resetPage = true;
      })
    );
  }, 1000);

  const handleSortChange = sortChange => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params.orderBy = sortChange.property;
        draft.params.order = sortChange.direction;
      })
    );
  };

  const isCollapsed = patientId => {
    return collapsed.find(item => patientId === item) !== undefined;
  };

  const columns = [
    {
      id: 'formatted_name',
      customSortId: 'last_name',
      numeric: false,
      disablePadding: false,
      label: 'Case Details',
      formatMethod: (fullName, row) => {
        return <PatientColumn collapsed={isCollapsed(row.id)} patient={row} />;
      }
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Dates',
      formatMethod: (value, row) => {
        return (
          <DateColumn
            started={row.created_at}
            modified={row.updated_at}
            completed={row.setup_completed_at}
            collapsed={isCollapsed(row.id)}
            completedLabel={
              row.workflow_data.print && !isEmpty(row.workflow_data.print)
                ? 'Approved'
                : 'Completed'
            }
          />
        );
      }
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Modified Date',
      type: 'datetime'
    },
    {
      id: 'requests',
      label: 'Requests',
      customSortId: 'request_status_updated_at',
      formatMethod: (value, row) => {
        return (
          <RequestsColumn
            collapsed={isCollapsed(row.id)}
            workflowData={row.workflow_data}
            requests={[]}
            assignments={[]}
          />
        );
      }
    }
  ];

  const renderFilters = () => {
    return (
      <Grid container alignItems="flex-end" spacing={3}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            placeholder="Search by Patient"
            className={classes.searchInput}
            defaultValue={globalParams.searchFullNameQuery}
            onChange={e => handleOnChangeSearchInput(e.target.value, 'searchFullNameQuery')}
          />
        </Grid>
        <Can
          organizationId={currentOrganization.id}
          permissions={['organization.patients.view-any']}
          yes={() => (
            <Grid item xs={12} sm={4}>
              <SearchInput
                placeholder="Search by Doctor"
                className={classes.searchInput}
                defaultValue={globalParams.searchDoctorFullNameOrOrganizationQuery}
                onChange={e =>
                  handleOnChangeSearchInput(
                    e.target.value,
                    'searchDoctorFullNameOrOrganizationQuery'
                  )
                }
              />
            </Grid>
          )}
        />
        <Grid item xs={12} sm={4}>
          <LocationSelectorCurrentOrganizationHandler
            onChange={handleChangeLocations}
            defaultValue={globalParams.locations}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <ExpandCollapseIconButton
                expandTitle="Expand All"
                collapseTitle="Collapse All"
                onClick={isCollapse => {
                  setCollapseAll(isCollapse);
                }}
                collapsed={collapseAll}
                className={classes.expandCollapseButton}
              />
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                value={onlyOnhold !== 'only' ? selectedCaseStatus : null}
                exclusive
                onChange={handleChangeCaseStatus}
              >
                {Object.keys(NEW_PATIENT_STATUSES).map(key => (
                  <StyledToggleButton
                    color={
                      onlyOnhold !== 'only' &&
                      selectedCaseStatus &&
                      NEW_PATIENT_STATUSES[key].order <
                        NEW_PATIENT_STATUSES[selectedCaseStatus].order
                        ? 'blue'
                        : 'default'
                    }
                    value={`${key}`}
                    key={key}
                    disabled={+key === +selectedCaseStatus || onlyOnhold === 'only'}
                  >
                    {NEW_PATIENT_STATUSES[key].name}
                    <span className={classes.totalCount}>{patientCountsPerStatus[key]}</span>
                  </StyledToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item />
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            {patientCountsPerStatus.held > 0 && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={onlyOnhold === 'only'}
                      onChange={handleChangeOnlyOnhold}
                      name="onlyOnhold"
                    />
                  }
                  label="Only On-hold"
                  className={classes.toggleOnHold}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const externalAppOpener = useMemo(
    () => ({
      CustomComponent: ({ row }) => {
        const isDisabled = patient => {
          const isInHouse =
            !patient.rx_form || patient.rx_form.submission_completed_by === ASSIGNMENTS.IN_OFFICE;
          const initialStatus = get(patient, 'workflow_data.initial.status');
          return (
            (!isInHouse &&
              (!initialStatus ||
                initialStatus === REQUEST_STATUS.NEW ||
                globalParams.newPatientStatus === REQUEST_STATUS.IN_PROGRESS ||
                !patient.ios_file_id)) ||
            patient.deleted_at
          );
        };

        const handleOpenExternal = (app, patient) => {
          setCurrentPatient(patient);
          setSelectedApp(app);

          if (isCompletedPatient(patient)) {
            dispatch(openPatientModificationWarning('Patients_List'));
            return;
          }

          const fileId = patient.ios_file_id;
          if (fileId) {
            dispatch(openDIBS(app, `open_file/${fileId}`));
          } else {
            dispatch(openDIBS(app, `open_patient/${patient.id}`));
          }
        };

        return (
          <OpenWithExternalApp
            patient={row}
            isIconButton={false}
            isDisabled={isDisabled}
            buttonProps={{
              name: 'openDibs',
              color: 'primary',
              title: 'Open DIBS',
              text: 'View',
              className: classes.compactButton
            }}
            onClick={app => handleOpenExternal(app, row)}
          />
        );
      }
    }),
    [classes, globalParams.newPatientStatus, dispatch]
  );

  return (
    <Content filters={renderFilters()}>
      <DataTable
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        total={total}
        updateData={handleUpdateData}
        defaultOrderBy={globalParams.orderBy}
        showVerticalLines
        customActions={[
          {
            name: 'showPatient',
            handleOnAction: handleShowPatient,
            text: 'Details',
            title: 'Show patient details',
            disabled: row => row.deleted_at && !canViewArchived
          },
          externalAppOpener
        ]}
        additionalParams={additionalParams}
        globalParams={globalParams}
        onSortChange={handleSortChange}
      />
      <PatientModificationWarning id="Patients_List" onClose={onPatientModificationWarningClose} />
    </Content>
  );
};

Patients.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object
};

Patients.defaultProps = {
  match: { params: {} }
};

export default Patients;

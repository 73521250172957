export const ATTRIBUTES = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  CASE_NUMBER_TEXT: 'case_number_text',
  DOCTOR: 'user_id',
  TREATMENT_LOCATION: 'location_id',
  INTRAORAL_SCAN_DATE: 'intraoral_scan_date',
  INITIAL_CASE_SETUP_ASSIGNMENT: 'submission_completed_by',
  ALLOWED_DIBS_OPTIONS: 'allowed_dibs_options',
  SCAN_SUBMISSION_METHOD: 'scan_submission_type',
  SCANNER: 'scanner_type',
  CUSTOM_SCANNER_NAME: 'custom_scanner_type_name',
  STL_FILES: 'stl_files',
  INTRAORAL_PHOTOS: 'rx_intraoral_photos',
  ARCH_TYPE: 'jaws_id',
  RUSH_CASE_ENABLED: 'rush_case_enabled',
  RUSH_CASE: 'rush_case',
  RUSH_CASE_AUTHORIZED_BY_NAME: 'rush_case_authorized_by',
  EXPORT_TYPE: 'export_type',
  PRINT_ASSIGNMENT: 'print_assignment',
  INSERT_BRACKETS_ASSIGNMENT: 'insert_brackets_assignment',
  SHIPPING_LOCATION: 'shipping_location_id',
  SHIPPING_METHOD: 'shipping_method',
  ROOT_INTEGRATION: 'root_integration',
  NOTES: 'special_instructions',
  BRACKET_LIBRARIES: 'bracket_libraries',
  BONDING_DATE: 'bonding_date',
  ARCH_WIRES: 'arch_wires',
  DICOM_FILES: 'dicom_files',
  ARCH_WIRE_OPTIONS: 'arch_wire_options',
  CUSTOM_SETUP_ENABLED: 'custom_setup_enabled',
  TEETH: 'teeth',
  SAVED_FOR_LATER: 'saved_for_later',
  // removed
  CUSTOM_SETUP: 'custom_setup',
  TRAY_DESIGN_ASSIGNMENT: 'tray_design_assignment'
};

/** Custom Fields */
export const ATTRIBUTE_CUSTOM_FIELDS = 'custom_fields';

export const STATEMENTS = {
  [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]: 'Your Initial case setup will be completed by:',
  [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: 'Upload Intraoral Scans.',
  [ATTRIBUTES.PRINT_ASSIGNMENT]: 'Print by:',
  [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: 'Insert brackets by:',
  [ATTRIBUTES.EXPORT_TYPE]: 'Export:'
};

export const QUESTIONS = {
  [ATTRIBUTES.FIRST_NAME]: 'First Name',
  [ATTRIBUTES.LAST_NAME]: 'Last Name',
  [ATTRIBUTES.CASE_NUMBER_TEXT]: 'Case Number',
  [ATTRIBUTES.TREATMENT_LOCATION]: 'Which Treatment Location?',
  [ATTRIBUTES.INTRAORAL_SCAN_DATE]: 'Intraoral Scan Date',
  [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]:
    'Who would you like to complete the initial case setup?',
  [ATTRIBUTES.RUSH_CASE_ENABLED]: 'Is rush case enabled?',
  [ATTRIBUTES.RUSH_CASE]: 'Is this a Rush Case?',
  [ATTRIBUTES.RUSH_CASE_AUTHORIZED_BY_NAME]: 'Name of the person authorizing the rush.',
  [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: 'How will you be submitting your scans?',
  [ATTRIBUTES.SCANNER]: 'What type of scanner do you have?',
  [ATTRIBUTES.CUSTOM_SCANNER_NAME]: 'What type of scanner do you have?',
  [ATTRIBUTES.STL_FILES]: 'Upload intraoral STL files.',
  [ATTRIBUTES.INTRAORAL_PHOTOS]: 'Upload intraoral STL files.',
  [ATTRIBUTES.ARCH_TYPE]: 'Which arches will you be bonding?',
  [ATTRIBUTES.EXPORT_TYPE]: 'What do you want to export?',
  [ATTRIBUTES.PRINT_ASSIGNMENT]: 'Who is printing?',
  [ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT]: 'Who will digitally design the trays?',
  [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: 'Who is inserting brackets?',
  [ATTRIBUTES.SHIPPING_LOCATION]: 'Which Shipping Location?',
  [ATTRIBUTES.SHIPPING_METHOD]: 'Which shipping option do you prefer?',
  [ATTRIBUTES.ROOT_INTEGRATION]:
    "Include Root & Bone integration. This option is ONLY available by uploading this patient's Cone Beam scans now.",
  [ATTRIBUTES.NOTES]:
    'Any extractions and/or mixed dentition? This helps w/ case quality assurance.',
  [ATTRIBUTES.BRACKET_LIBRARIES]:
    'Choose the brackets for this case (these can easily be changed later) ',
  [ATTRIBUTES.DOCTOR]: 'Choose Doctor',
  [ATTRIBUTES.BONDING_DATE]: 'Is bonding date enabled?',
  [ATTRIBUTES.ARCH_WIRES]: '(Optional) Choose your finishing archwire',
  [ATTRIBUTES.ALLOWED_DIBS_OPTIONS]: 'Which DIBS options are supported?',
  [ATTRIBUTES.ARCH_WIRE_OPTIONS]: 'Which finishing archwires (built-in) are supported?',
  [ATTRIBUTES.CUSTOM_SETUP_ENABLED]: 'Is custom setups enabled?',
  [ATTRIBUTES.CUSTOM_SETUP]:
    'Would you like a better, custom setup based on your doctor preferences?',
  [ATTRIBUTES.TEETH]: 'Teeth'
};

export const VIEW_LABEL = {
  [ATTRIBUTES.BRACKET_LIBRARIES]: 'Choose the brackets for this case',
  created_by_user_id: 'Filled By',
  [ATTRIBUTES.BONDING_DATE]: 'When would you like to receive this case?',
  [ATTRIBUTES.ARCH_WIRES]: 'Choose your finishing archwire',
  [ATTRIBUTES.DOCTOR]: 'Who is the Doctor?'
};

export const HELPER_TEXT = {
  [ATTRIBUTES.RUSH_CASE]:
    'This case will be ready for doctor approval within one hour when submitted during regular business hours: Mon. - Fri. 8am - 4pm (MST).'
};

export const SUB_LABEL = {
  [ATTRIBUTES.RUSH_CASE]:
    'Note: expedited shipping is a separate option selected by the doctor when approving this case.'
};

const createOptions = options => {
  return Object.entries(options).map(([value, label]) => ({
    value,
    label
  }));
};

export const ASSIGNMENTS = {
  ORTHOSELECT: 1,
  IN_OFFICE: 2,
  THIRD_PARTY: 3
};

export const DIBS_OPTIONS = {
  DIBS_DESKTOP: 1,
  DIBS_ONLINE: 4
};

export const SCAN_SUBMISSION_METHODS = {
  UPLOAD: 1,
  SCANNER: 2
};

export const ARCHES = {
  MAXILLARY: 1,
  MANDIBULAR: 2,
  BOTH: 3
};

export const EXPORTS = {
  TRAYS: 1,
  GUIDES: 2,
  BRACKETS: 3
};

export const SCANNERS = {
  ITERO: 1,
  THREE_SHAPE: 2,
  THREE_M: 3,
  CARESTREAM: 4,
  CEREC: 5,
  MEDIT: 6,
  OTHER: 7
};

export const ASSIGNMENT_LABELS = {
  [ASSIGNMENTS.ORTHOSELECT]: 'OrthoSelect',
  [ASSIGNMENTS.IN_OFFICE]: 'In-office',
  [ASSIGNMENTS.THIRD_PARTY]: '3PP'
};

export const TRAY_DESIGN_ASSIGNMENT_LABELS = {
  [ASSIGNMENTS.ORTHOSELECT]: 'OrthoSelect',
  [ASSIGNMENTS.IN_OFFICE]: 'In-office'
};

export const ASSIGNMENT_SHORT_LABELS = {
  [ASSIGNMENTS.ORTHOSELECT]: 'O',
  [ASSIGNMENTS.IN_OFFICE]: 'H',
  [ASSIGNMENTS.THIRD_PARTY]: '3'
};

export const DIBS_OPTIONS_LABELS = {
  [DIBS_OPTIONS.DIBS_DESKTOP]: 'Desktop',
  [DIBS_OPTIONS.DIBS_ONLINE]: 'Online'
};

export const SCAN_SUBMISSION_LABELS = {
  [SCAN_SUBMISSION_METHODS.UPLOAD]: 'I am uploading STL files directly to this form.',
  [SCAN_SUBMISSION_METHODS.SCANNER]: 'I am sending scans directly from my intraoral scanner.'
};

const ARCH_LABELS = {
  [ARCHES.MAXILLARY]: 'Maxillary',
  [ARCHES.MANDIBULAR]: 'Mandibular',
  [ARCHES.BOTH]: 'Both'
};

export const EXPORT_LABELS = {
  [EXPORTS.TRAYS]: '3D Printed IDB Trays',
  [EXPORTS.GUIDES]: 'Guides',
  [EXPORTS.BRACKETS]: 'Brackets'
};

export const SCANNER_LABELS = {
  [SCANNERS.ITERO]: 'iTero',
  [SCANNERS.THREE_SHAPE]: '3Shape',
  [SCANNERS.THREE_M]: '3M',
  [SCANNERS.CARESTREAM]: 'Carestream',
  [SCANNERS.CEREC]: 'Cerec',
  [SCANNERS.MEDIT]: 'Medit',
  [SCANNERS.OTHER]: 'Other'
};

export const ARCH_OPTIONS = createOptions(ARCH_LABELS);

export const EXPORT_OPTIONS = createOptions(EXPORT_LABELS);

export const SCANNER_OPTIONS = createOptions(SCANNER_LABELS);

export const REMOVED_SCANNERS = [SCANNERS.THREE_M];

export const OPTIONS = {
  [ATTRIBUTES.ARCH_TYPE]: ARCH_OPTIONS,
  [ATTRIBUTES.EXPORT_TYPE]: EXPORT_OPTIONS,
  [ATTRIBUTES.SCANNER]: SCANNER_OPTIONS
};

export const ATTRIBUTE_VALUE_LABELS = {
  [ATTRIBUTES.INITIAL_CASE_SETUP_ASSIGNMENT]: ASSIGNMENT_LABELS,
  [ATTRIBUTES.PRINT_ASSIGNMENT]: ASSIGNMENT_LABELS,
  [ATTRIBUTES.TRAY_DESIGN_ASSIGNMENT]: TRAY_DESIGN_ASSIGNMENT_LABELS,
  [ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT]: ASSIGNMENT_LABELS,
  [ATTRIBUTES.SCAN_SUBMISSION_METHOD]: SCAN_SUBMISSION_LABELS,
  [ATTRIBUTES.ARCH_TYPE]: ARCH_LABELS,
  [ATTRIBUTES.SCANNER]: SCANNER_LABELS,
  [ATTRIBUTES.EXPORT_TYPE]: EXPORT_LABELS
};

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import { fetchCurrentUserAction } from '../../reducers/auth';
import TabPanel from '../../components/TabPanel';
import InstallersManagement from './Installer/InstallersManagement';
import Pricing from './Pricing';
import AnnouncementManager from './Announcement/Manager';
import hasPermission from '../../selectors/hasPermission';
import FormBuilder from './FormBuilder';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

const Settings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentOrganization = useSelector(state => state.auth.currentOrganization);

  const canUpdateGlobalPricing = useSelector(state =>
    hasPermission(state, { permissions: ['pricing.update'] })
  );
  const canUpdateAnnoucement = useSelector(state =>
    hasPermission(state, { permissions: ['announcement.update'] })
  );
  const canCustomizeRxForm = useSelector(state =>
    hasPermission(state, { permissions: ['rx-forms.customize-default-layout'] })
  );
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const queryStringParams = parse(location.search);
    if (queryStringParams.tab) {
      setValue(parseInt(queryStringParams.tab, 10));
    }
    dispatch(fetchCurrentUserAction());
  }, [dispatch, location]);

  if (currentOrganization && !currentOrganization.contract) {
    return null;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Installers Management" value={0} />

          {canUpdateGlobalPricing && <Tab label="Customize Fees" value={1} />}

          {canUpdateAnnoucement && <Tab label="Announcements" value={2} />}

          {canCustomizeRxForm && <Tab label="Customize RxForm" value={3} />}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <InstallersManagement />
      </TabPanel>
      {canUpdateGlobalPricing && (
        <TabPanel value={value} index={1}>
          <Pricing canUpdateGlobalPricing={canUpdateGlobalPricing} />
        </TabPanel>
      )}
      {canUpdateAnnoucement && (
        <TabPanel value={value} index={2}>
          <AnnouncementManager />
        </TabPanel>
      )}

      {canCustomizeRxForm && (
        <TabPanel value={value} index={3}>
          <FormBuilder />
        </TabPanel>
      )}
    </div>
  );
};

export default Settings;

import PropTypes from 'prop-types';
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ATTRIBUTE_VALUE_LABELS, ATTRIBUTES, QUESTIONS, VIEW_LABEL } from '../constants';
import Teeth from '../Teeth';
import { useRxFormPreference } from '../hooks';
import getNativeQuestions from './getNativeQuestions';
import getCustomQuestions from './getCustomQuestions';

const useStyles = makeStyles(theme => ({
  bracketsList: {
    paddingLeft: theme.spacing(2.5)
  },
  teethDivider: {
    marginBottom: theme.spacing(1.5)
  },
  question: {
    padding: theme.spacing(1),
    marginTop: 2,
    marginBottom: 2,
    borderRadius: 4,
    '& ul': {
      paddingInlineStart: 0,
      listStyleType: 'none',
      '& > li': {
        borderRadius: 4,
        padding: 4,
        marginBottom: 4
      }
    }
  }
}));

const getOrderedFields = formBuilderData => {
  const orderedFields = new Set([
    ATTRIBUTES.DOCTOR,
    ATTRIBUTES.PRINT_ASSIGNMENT,
    ATTRIBUTES.INSERT_BRACKETS_ASSIGNMENT
  ]);

  formBuilderData.layout.forEach(section => {
    section.children.forEach(col => {
      col.children.forEach(field => {
        orderedFields.add(field.system_name);
      });
    });
  });

  return [...orderedFields];
};

const View = ({ rxForm }) => {
  const styles = useStyles();
  const { preference } = useRxFormPreference();
  const isManagement = useSelector(state => state.auth.isManagement);

  const questions = [
    ...getNativeQuestions(rxForm, preference, styles),
    ...getCustomQuestions(rxForm.form_builder_data.form)
  ];

  const orderedFields = getOrderedFields(rxForm.form_builder_data);

  return (
    <Grid container>
      {orderedFields.map(attribute => {
        const questionIndex = questions.findIndex(q => q.attribute === attribute);
        if (questionIndex === -1) {
          return '';
        }
        const question = questions[questionIndex];
        const {
          customLabel,
          generateLabel = null,
          generateContentProps = null,
          gridSize = 6,
          hide = false,
          labelProps = {},
          custom = false,
          skip = null
        } = question;

        if (skip && skip(rxForm)) {
          return '';
        }

        let label = rxForm[attribute];
        if (custom) {
          label = rxForm.custom_field_values[attribute];
        }

        if (label === null || label === undefined || hide) {
          if (!customLabel) return '';
        }

        let wrapperProps = {};

        if (generateContentProps !== null) {
          const contentProps = generateContentProps(label, isManagement);
          label = contentProps.label;
          wrapperProps = contentProps.wrapperProps || {};
        } else if (generateLabel !== null) {
          label = generateLabel(label, rxForm);
        } else if (ATTRIBUTE_VALUE_LABELS[attribute]) {
          label = ATTRIBUTE_VALUE_LABELS[attribute][label];
        } else if (typeof label === 'boolean') {
          label = label ? 'Yes' : 'No';
        }

        return (
          <Grid key={attribute} item xs={gridSize}>
            <ListItemText
              primary={customLabel || VIEW_LABEL[attribute] || QUESTIONS[attribute]}
              className={styles.question}
              secondary={label}
              secondaryTypographyProps={labelProps}
              {...wrapperProps}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Divider className={styles.teethDivider} />
        <Teeth data={rxForm.teeth || {}} viewOnly />
      </Grid>
    </Grid>
  );
};

View.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rxForm: PropTypes.object.isRequired
};

export default View;
